<template>
  <v-container fluid>
    <!-- App bar -->
    <v-app-bar app color="primary">
      <v-toolbar-title class="white-text text--white white--text">Zuzy Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text class="white" @click="logout">Logout</v-btn>
    </v-app-bar>

    <!-- User info -->

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>Change Redirect Link</v-card-title>
        <v-card-text>
          <v-text-field v-model="newRedirectLink" label="New Redirect Link"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="updateRedirectLink">Update</v-btn>
          <v-btn @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center" class="my-4">
      <v-col cols="12">
        <v-card>
          <v-card-title>User Information</v-card-title>
          <v-card-text>
            <div>Name: {{ user.name }}</div>
            <div>Email: {{ user.email }}</div>
            <div>Phone: {{ user.phone }}</div>

            <!-- You can display more user information here -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Product list -->
    <v-row>
      <v-col class="col-5">
        <span class=" title">My products</span>
      </v-col>
      <v-col class="col-7" justify="end" align="end">
        <v-btn color="primary" small @click="handleNewButtonClick">
          <v-icon class="mr-4" small>mdi-qrcode-scan</v-icon>
          Add New</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col v-for="product in products" :key="product._id" cols="12" sm="6" md="4" lg="3">
        <v-card>
          <v-card-title> {{ product.productDetails.type }}</v-card-title>
          <v-card-text>
            <div>Link Visits :{{ product.visitorCount }}</div>
            <div>Color : {{ product.productDetails.color }}</div>
            <div>
              Link : <a :href="product.redirectURL">{{ product.redirectURL }}</a>
            </div>
            <p>
              <v-btn class="mt-5" block outlined @click="editRedirectURL(product, user._id)" color="primary"
                >Edit Link</v-btn
              >
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      dialog: false,
      newRedirectLink: null,
      user: {},
      products: [],
      selectedProduct: null,
    }
  },
  created() {
    // Retrieve user data and access token from local storage
    const userData = JSON.parse(localStorage.getItem('user'))
    const accessToken = localStorage.getItem('accessToken')

    // Set user data in the component data
    this.user = userData
    this.loadQrData(userData._id)
  },
  methods: {
    updateRedirectLink() {
      // Call the API endpoint to update the redirect link
      axios
        .post('https://api.zuzy.in/api/update-existing-redirect', {
          qrcodeId: this.selectedProduct._id,
          userId: this.user._id,
          redirectLink: this.newRedirectLink,
        })
        .then(response => {
          if (response.data.success) {
            // If the update operation was successful, do something (e.g., display a success message)
            console.log('Redirect link updated successfully')
            this.dialog = false
            this.selectedProduct = null
            this.newRedirectLink = null
            this.loadQrData(this.user._id);
            this.$router.push('/dashboard')
          } else {
            // If the update operation failed, handle the error (e.g., display an error message)
            console.error('Failed to update redirect link:', response.data.error)
            this.$swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'QR code is already registered. Please contact 7016241044 for assistance.',
            })
            this.dialog = false
            this.scannedQrCodeId = null
            this.newRedirectLinkId = null
            this.$router.push('/dashboard')
          }
        })
        .catch(error => {
          // Handle API call errors (e.g., network error)
          console.error('Error updating redirect link:', error)
        })
    },
    closeDialog() {
      // Close the dialog box without updating the redirect link
      this.dialog = false
    },
    async editRedirectURL(product, userId) {
      this.newRedirectLink = product.redirectURL
      this.selectedProduct = product
      this.dialog = true
    },
    async loadQrData(userId) {
      const response = await axios.get(`https://api.zuzy.in/api/qrcodes/${userId}`)

      // Set the fetched data to qrCodesWithProducts array
      this.products = response.data
      console.log(response.data)
    },
    handleNewButtonClick() {
      this.$router.push('/qrcode')
      // Handle click event for the New button
    },
    logout() {
      // Handle logout functionality
      // Clear user data and access token from local storage
      localStorage.removeItem('user')
      localStorage.removeItem('accessToken')
      // Optionally, redirect to the login page
      this.$router.push('/login')
    },
  },
}
</script>

<style scoped>
/* Add custom styles here */
.avatar-with-border {
  border: 2px solid #000; /* Example border style */
}
</style>
